<template>

    <div>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container class="container-filtering">

            <v-row align="center">
 
                <!-- Column Select Station -->
                <!-- <v-col
                class="d-flex"
                cols="12"
                sm="6"
                md="4"
                > -->
                <v-col
                class="col-input-filter"
                >
                    <v-select
                    v-on:change="selectValueStation"
                    :items="itemStation"
                    label="Station"
                    ></v-select>
                </v-col>

                <!-- Column Select Calendar From-->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    v-model="modalCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateFrom"
                        label="From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateFrom"
                        @input="modalCalendar = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Select Calendar To-->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    v-model="modalCalendar2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateTo"
                        label="To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateTo"
                        @input="modalCalendar2 = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- <v-col>
                    <v-text-field @input="test">

                    </v-text-field>
                </v-col> -->

                <!-- Column Generate Button -->
                <v-col
                cols="12"
                sm="6"
                md="4"
                class="col-btn-generateReport"
                >
                    <v-btn
                    id="generateReport"
                    class="ma-2 white--text btn-generate primary"
                    solo
                    dense
                    normal
                    @click="generateTable"
                    >
                      Generate Report
                      <v-icon
                      right
                      dark
                      big
                      >
                          mdi-table-arrow-up
                      </v-icon>
                    </v-btn>

                    
                </v-col>

            </v-row>

        </v-container>

        <!-- Section Result Generated Report (TABLE) -->
        <v-container class="container-result-generated form-datatable">

            <!-- Main Report Title/Tab -->
            <!-- <v-tabs>
                <v-tab>QAQC Data Validation Report</v-tab>
                <v-tab>QAQC Data Validation Pending</v-tab>
                <v-tab>QAQC Data Validation History</v-tab>
            </v-tabs> -->

            <v-tabs
            v-model="tabs"
            >
                <v-tab v-for="item in tabsTypeValidation" :key="item.tab" >
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <!-- Mini Title (Table Title) -->
            <v-subheader
            class="subTitle fontSubTitle-blue"
            >
            Data For Station {{locationStation}} ({{stationID}})
            </v-subheader>

            <v-tabs-items v-model="tabs">

                <!-- Content Tab Validation Report -->
                <v-tab-item>

                    <div id="pageDepanValidationReport">

                        <!-- Button Export Table -->
                        <div class="section-btn-export">
                            <v-btn
                            color="green"
                            elevation="2"
                            @click="exportReport('excel','report')"
                            class="btn-export-table btn-excel"
                            small
                            >
                                <v-icon
                                color="white"
                                center
                                dark
                                small
                                >
                                    mdi-microsoft-excel
                                </v-icon>
                            </v-btn>
                        </div>
                        
                        
                        <!-- Table Report-->
                        <v-data-table
                        :headers="thead_validation_report"
                        :items="tbody_validation_report"
                        :header-props="{ sortIcon: null }"
                        class="elevation-1 tableValidationReport headerDtSarawak"
                        :items-per-page="10"
                        no-select-on-click
                        fixed-header
                        :loading="this.loadingTable"
                        :loading-text="this.loadingText"
                        >
                            <template v-slot:[`item.datetime_a`]="{ item }">
                                <!-- {{ item.datetime_a }} -->
                                <span v-html="item.datetime_a"></span>
                            </template>


                            <!-- Slot Parameter Start -->

                            <template v-slot:[`item.WQI`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'WQI',item.WQI,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.WQI }}
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SI_DO_SAT`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SI_DO_SAT',item.SI_DO_SAT,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_DO_SAT }}
                                    <!-- <p style="color:red;font-weight:bold;">{{ item.DOSAT_FLAG }}</p> -->
                                </v-btn>
                            </template>

                            <template v-slot:[`item.DO_CON`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'DO_CON',item.DO_CON,$event,item.DOCON_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.DO_CON }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DOCON_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.DO_SAT`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'DO_SAT',item.DO_SAT,$event,item.DOSAT_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.DO_SAT }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DOSAT_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SI_BOD`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SI_BOD',item.SI_BOD,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_BOD }} 
                                    <!-- <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DOSAT_FLAG }}</p> -->
                                </v-btn>
                            </template>

                            <template v-slot:[`item.BOD`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'BOD',item.BOD,$event,item.BOD_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.BOD }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BOD_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SI_COD`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SI_COD',item.SI_COD,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_COD }} 
                                    <!-- <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BOD_FLAG }}</p> -->
                                </v-btn>
                            </template>

                            <template v-slot:[`item.COD`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'COD',item.COD,$event,item.COD_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.COD }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.COD_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SI_NH3N`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SI_NH3N',item.SI_NH3N,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_NH3N }} 
                                    <!-- <p style="color:red;font-weight:bold;" class="val-flag">{{ item.COD_FLAG }}</p> -->
                                </v-btn>
                            </template>

                            <template v-slot:[`item.NH3N`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'NH3N',item.NH3N,$event,item.NH3N_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.NH3N }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH3N_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SI_TSS`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SI_TSS',item.SI_TSS,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_TSS }} 
                                    <!-- <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH3N_FLAG }}</p> -->
                                </v-btn>
                            </template>

                            <template v-slot:[`item.TSS`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TSS',item.TSS,$event,item.TSS_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TSS }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TSS_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SI_pH`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SI_pH',item.SI_pH,$event)" tile elevation="0" class="btn-click-td">
                                    {{ item.SI_pH }} 
                                    <!-- <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TSS_FLAG }}</p> -->
                                </v-btn>
                            </template>

                            <template v-slot:[`item.pH`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'pH',item.pH,$event,item.pH_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.pH }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.pH_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item._4_4_DDT`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'_4_4_DDT',item._4_4_DDT,$event,item._4_4_DDT_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item._4_4_DDT }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item._4_4_DDT_FLAG }}</p>
                                </v-btn>
                            </template>


                            <template v-slot:[`item.AG`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'AG',item.AG,$event,item.AG_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.AG }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AG_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.AL`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'AL',item.AL,$event,item.AL_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.AL }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AL_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.ALDRIN_DIELDRIN`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'ALDRIN_DIELDRIN',item.ALDRIN_DIELDRIN,$event,item.ALDRIN_DIELDRIN_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.ALDRIN_DIELDRIN }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ALDRIN_DIELDRIN_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.AS`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'AS',item.AS,$event,item.AS_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.AS }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.AS_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.B`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'B',item.B,$event,item.B_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.B }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.B_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.BA`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'BA',item.BA,$event,item.BA_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.BA }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BA_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.BHC`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'BHC',item.BHC,$event,item.BHC_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.BHC }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BHC_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.BR`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'BR',item.BR,$event,item.BR_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.BR }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.BR_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CA`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CA',item.CA,$event,item.CA_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CA }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CA_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CCE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CCE',item.CCE,$event,item.CCE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CCE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CCE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CD`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CD',item.CD,$event,item.CD_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CD }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CD_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CDOM`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CDOM',item.CDOM,$event,item.CDOM_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CDOM }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CDOM_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CHLORDANE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CHLORDANE',item.CHLORDANE,$event,item.CHLORDANE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CHLORDANE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CHLORDANE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CHLOROPHYLL_A`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CHLOROPHYLL_A',item.CHLOROPHYLL_A,$event,item.CHLOROPHYLL_A_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CHLOROPHYLL_A }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CHLOROPHYLL_A_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CL`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CL',item.CL,$event,item.CL_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CL }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CL_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CL2`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CL2',item.CL2,$event,item.CL2_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CL2 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CL2_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CN`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CN',item.CN,$event,item.CN_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CN }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CN_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CO2`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CO2',item.CO2,$event,item.CO2_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CO2 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CO2_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.COLOUR`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'COLOUR',item.COLOUR,$event,item.COLOUR_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.COLOUR }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.COLOUR_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CR`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CR',item.CR,$event,item.CR_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CR }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CR_III`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CR_III',item.CR_III,$event,item.CR_III_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CR_III }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_III_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CR_IV`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CR_IV',item.CR_IV,$event,item.CR_IV_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CR_IV }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CR_IV_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.CU`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'CU',item.CU,$event,item.CU_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.CU }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.CU_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.DEPTH`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'DEPTH',item.DEPTH,$event,item.DEPTH_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.DEPTH }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DEPTH_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.DOC`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'DOC',item.DOC,$event,item.DOC_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.DOC }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.DOC_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.E_COLI`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'E_COLI',item.E_COLI,$event,item.E_COLI_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.E_COLI }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.E_COLI_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.EC`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'EC',item.EC,$event,item.EC_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.EC }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.EC_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.ENDOSULFAN`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'ENDOSULFAN',item.ENDOSULFAN,$event,item.ENDOSULFAN_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.ENDOSULFAN }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ENDOSULFAN_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.F`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'F',item.F,$event,item.F_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.F }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.F_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.FC`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'FC',item.FC,$event,item.FC_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.FC }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FC_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.FDOM`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'FDOM',item.FDOM,$event,item.FDOM_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.FDOM }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FDOM_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.FE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'FE',item.FE,$event,item.FE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.FE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.FLOWRATE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'FLOWRATE',item.FLOWRATE,$event,item.FLOWRATE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.FLOWRATE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.FLOWRATE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.GROSS_ALPHA`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'GROSS_ALPHA',item.GROSS_ALPHA,$event,item.GROSS_ALPHA_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.GROSS_ALPHA }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.GROSS_ALPHA_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.GROSS_BETA`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'GROSS_BETA',item.GROSS_BETA,$event,item.GROSS_BETA_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.GROSS_BETA }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.GROSS_BETA_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.HARDNESS`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'HARDNESS',item.HARDNESS,$event,item.HARDNESS_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.HARDNESS }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HARDNESS_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.HEDONAL_2_4_D`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'HEDONAL_2_4_D',item.HEDONAL_2_4_D,$event,item.HEDONAL_2_4_D_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.HEDONAL_2_4_D }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HEDONAL_2_4_D_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.HEPTACHLOR_EPOXIDE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'HEPTACHLOR_EPOXIDE',item.HEPTACHLOR_EPOXIDE,$event,item.HEPTACHLOR_EPOXIDE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.HEPTACHLOR_EPOXIDE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HEPTACHLOR_EPOXIDE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.HG`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'HG',item.HG,$event,item.HG_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.HG }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.HG_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.K`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'K',item.K,$event,item.K_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.K }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.K_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.LINDANE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'LINDANE',item.LINDANE,$event,item.LINDANE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.LINDANE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.LINDANE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.MBAS`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'MBAS',item.MBAS,$event,item.MBAS_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.MBAS }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MBAS_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.MG`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'MG',item.MG,$event,item.MG_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.MG }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MG_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.MN`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'MN',item.MN,$event,item.MN_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.MN }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.MN_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.NA`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'NA',item.NA,$event,item.NA_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.NA }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NA_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.NH4`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'NH4',item.NH4,$event,item.NH4_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.NH4 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NH4_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.NI`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'NI',item.NI,$event,item.NI_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.NI }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NI_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.NO2`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'NO2',item.NO2,$event,item.NO2_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.NO2 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NO2_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.NO3`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'NO3',item.NO3,$event,item.NO3_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.NO3 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.NO3_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.OG_EDIBLE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'OG_EDIBLE',item.OG_EDIBLE,$event,item.OG_EDIBLE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.OG_EDIBLE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OG_EDIBLE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.OG_MINERAL`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'OG_MINERAL',item.OG_MINERAL,$event,item.OG_MINERAL_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.OG_MINERAL }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.OG_MINERAL_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.P`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'P',item.P,$event,item.P_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.P }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.P_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.PARAQUAT`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'PARAQUAT',item.PARAQUAT,$event,item.PARAQUAT_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.PARAQUAT }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PARAQUAT_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.PB`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'PB',item.PB,$event,item.PB_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.PB }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PB_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.PCB`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'PCB',item.PCB,$event,item.PCB_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.PCB }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PCB_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.PHENOL`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'PHENOL',item.PHENOL,$event,item.PHENOL_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.PHENOL }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PHENOL_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.PO4_3`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'PO4_3',item.PO4_3,$event,item.PO4_3_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.PO4_3 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.PO4_3_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.RA226`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'RA226',item.RA226,$event,item.RA226_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.RA226 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.RA226_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.S`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'S',item.S,$event,item.S_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.S }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.S_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SALINITY`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SALINITY',item.SALINITY,$event,item.SALINITY_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SALINITY }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SALINITY_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SE',item.SE,$event,item.SE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SILICA`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SILICA',item.SILICA,$event,item.SILICA_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SILICA }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SILICA_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SILVEX_2_4_5_TP`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SILVEX_2_4_5_TP',item.SILVEX_2_4_5_TP,$event,item.SILVEX_2_4_5_TP_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SILVEX_2_4_5_TP }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SILVEX_2_4_5_TP_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SN`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SN',item.SN,$event,item.SN_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SN }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SN_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SO4`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SO4',item.SO4,$event,item.SO4_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SO4 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SO4_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SOLAR_BATTERY`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SOLAR_BATTERY',item.SOLAR_BATTERY,$event,item.SOLAR_BATTERY_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SOLAR_BATTERY }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SOLAR_BATTERY_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.SR_90`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'SR_90',item.SR_90,$event,item.SR_90_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.SR_90 }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.SR_90_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.TC`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TC',item.TC,$event,item.TC_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TC }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TC_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.TDS`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TDS',item.TDS,$event,item.TDS_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TDS }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TDS_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.TEMPERATURE`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TEMPERATURE',item.TEMPERATURE,$event,item.TEMPERATURE_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TEMPERATURE }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TEMPERATURE_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.TOC`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TOC',item.TOC,$event,item.TOC_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TOC }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TOC_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.TRIOXONE_2_4_5_T`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TRIOXONE_2_4_5_T',item.TRIOXONE_2_4_5_T,$event,item.TRIOXONE_2_4_5_T_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TRIOXONE_2_4_5_T }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TRIOXONE_2_4_5_T_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.TURBIDITY`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TURBIDITY',item.TURBIDITY,$event,item.TURBIDITY_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TURBIDITY }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TURBIDITY_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.U`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'U',item.U,$event,item.U_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.U }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.U_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.VELOCITY`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'VELOCITY',item.VELOCITY,$event,item.VELOCITY_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.VELOCITY }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.VELOCITY_FLAG }}</p>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.ZN`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'ZN',item.ZN,$event,item.ZN_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.ZN }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.ZN_FLAG }}</p>
                                </v-btn>
                            </template>


                            <!-- TAMBAHAN PARAM! -->
                            <template v-slot:[`item.TRYPHPTOPHAN`]="{ item }">
                                <v-btn @click="collectDataTD(item.STATION_ID,item.datetime_a,'TRYPHPTOPHAN',item.TRYPHPTOPHAN,$event,item.TRYPHPTOPHAN_FLAG)" tile elevation="0" class="btn-click-td">
                                    {{ item.TRYPHPTOPHAN }} 
                                    <p style="color:red;font-weight:bold;" class="val-flag">{{ item.TRYPHPTOPHAN_FLAG }}</p>
                                </v-btn>
                            </template>


                            <!-- Slot Parameter End -->






                        </v-data-table>

                        <div id="container_btnDpn_valReport" class="center-el" style="text-align:center;">
                            <v-btn
                            v-if="adminCanUse || operationCanUse || qaqcCanUse"
                            class="btn-after-table primary"
                            @click="submitDataTD"
                            >
                                Submit
                            </v-btn>

                            <v-btn
                            class="btn-after-table primary"
                            @click="cancelSelectTD"
                            >
                                Cancel
                            </v-btn>
                        </div>

                    </div>


                    <div id="pageLepasSubmitSelectedTD">

                        <v-data-table
                        :headers="thead_fromTD_validation_report"
                        :items="tbody_fromTD_validation_report"
                        :header-props="{ sortIcon: null }"
                        class="elevation-1 tableValidationReport headerDtSarawak"
                        disable-items-per-page
                        disable-pagination
                        no-select-on-click
                        >
                            <template v-slot:[`item.datetime`]="{ item }">
                                <!-- {{ item.datetime_a }} -->
                                <span v-html="item.datetime"></span>
                            </template>

                        </v-data-table>

                        <div class="remarksTd" style="text-align:center;">

                            <v-textarea
                            class="textarea-cust"
                            outlined
                            label="Remarks"
                            @input="inputRemarksDataTD($event)"
                            ></v-textarea>

                            <v-btn
                            class="btn-after-table primary"
                            @click="submitRemarksDataTD"
                            :loading="submitRemarksDataTDLoading"
                            >
                                Submit
                            </v-btn>

                            <v-btn
                            class="btn-after-table primary"
                            @click="backToMainValidationReport"
                            >
                                Cancel
                            </v-btn>
                        </div>

                    </div>


                    <!-- Popups/Dialogs Section Tab Report-->
                    <div class="text-center">
                        <v-dialog
                        v-model="dialogSucceedValidateReport"
                        width="500"
                        persistent
                        >
                        <!-- <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            style="display:none;"
                            id="showSucceedValidateReport"
                            color="red lighten-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            small
                            >
                                <v-icon small>mdi-file-clock-outline</v-icon>
                            </v-btn>
                        </template> -->

                        <v-card>
                            <v-card-title class="text-h5" style="background:#106f79;color:white;">
                            Success! <v-icon style="color:white;margin-left:15px;">mdi-file-clock-outline</v-icon>
                            </v-card-title>

                            <v-card-text style="padding:20px 24px;font-weight:bold;">
                            Your request submitted successfully and pending for approval confirmation
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="dialogSucceedValidateReport = false; generateTable();"
                            >
                                OK
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>

                    
                    

                </v-tab-item>

                <!-- Content Tab Validation Pending -->
                <v-tab-item>

                    <!-- Button Export Table -->
                    <div class="section-btn-export">
                        <!-- <v-btn
                        color="green"
                        elevation="2"
                        @click="exportReport('excel','pending')"
                        class="btn-export-table btn-excel"
                        small
                        >
                            <v-icon
                            color="white"
                            center
                            dark
                            small
                            >
                                mdi-microsoft-excel
                            </v-icon>
                        </v-btn> -->
                    </div>

                    <!-- Table Pending-->
                    <v-data-table
                    v-model="selected"
                    :headers="thead_validation_pending"
                    :items="tbody_validation_pending"
                    :header-props="{ sortIcon: null }"
                    class="elevation-1 tableValidationPending headerDtSarawak"
                    :items-per-page="5"
                    no-select-on-click
                    >

                        <template v-slot:[`item.datetime`]="{ item }">
                            <span v-html="item.datetime"></span>
                        </template>

                        <template v-slot:[`item.requestedDt`]="{ item }">
                            <span v-html="item.requestedDt"></span>
                        </template>

                        <template v-slot:[`item.actionDropdown`]="{ item }">
                            <!-- {{ item.actionDropdown }} -->
                            <v-container>
                                <v-select
                                v-if="adminCanUse || operationCanUse"
                                :items="item.actionDropdown"
                                class="action-chips"
                                small
                                dense
                                small-chips
                                solo
                                :disabled="item.requestedBy == whichUser"
                                v-on:change="actionPending(item.id,$event)"
                                >
                                </v-select>
                            </v-container>
                        </template>

                        <template v-slot:[`item.actionRemarks`]="{ item }">
                            <v-textarea
                            v-if="adminCanUse || operationCanUse"
                            id="actionRemarksPending"
                            v-model="item.actionRemarks"
                            small
                            dense
                            solo
                            full-width
                            rows="2"
                            :disabled="item.requestedBy == whichUser"
                            @input="getdataTextArea(item.id,$event)"
                            >
                            </v-textarea>
                        </template>

                        <template v-slot:[`item.submitCheckbox`]="{ item }">
                            <v-simple-checkbox
                            v-if="adminCanUse || operationCanUse"
                            class="checkbox-dt"
                            :value="item.id"
                            v-model="item.submitCheckbox"
                            v-ripple
                            :disabled="item.requestedBy == whichUser"
                            @click="check(item.actionRemarks,item.id,item.submitCheckbox,$event)"
                            ></v-simple-checkbox>
                        </template>

                    </v-data-table>

                    <div class="center-el" style="text-align:center;" v-if="adminCanUse || operationCanUse">
                        <v-btn
                        v-if="adminCanUse || operationCanUse || qaqcCanUse"
                        :disabled="selectedPendingToHistory.length == 0? true : false"
                        class="btn-after-table primary"
                        @click="submitDataPending"
                        >
                            Submit
                        </v-btn>

                        <!-- <v-btn
                        id="toggleChkUnck"
                        class="btn-after-table primary"
                        @click="checkingAll"
                        >
                            Select/Deselect All
                        </v-btn> -->
                    </div>


                    <!-- Popups/Dialogs Succeed Section Tab Pending -->
                    <div class="text-center">
                        <v-dialog
                        v-model="dialogSucceedPending"
                        width="500"
                        >

                        <v-card>
                            <v-card-title class="text-h5" style="background:#106f79;color:white;">
                            Success! <v-icon style="color:white;margin-left:15px;">mdi-text-box-check-outline</v-icon>
                            </v-card-title>

                            <v-card-text style="padding:20px 24px;font-weight:bold;">
                            All data selected submitted successfully.
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="alreadySuccessSubmitPending"
                            >
                                OK
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>


                    <!-- Popups/Dialogs Error Section Tab Pending -->
                    <div class="text-center">
                        <v-dialog
                        v-model="dialogErrorPending"
                        width="500"
                        >

                        <v-card>
                            <v-card-title class="text-h5 red lighten-2" style="color:white;">
                            Warning! <v-icon style="color:white;margin-left:15px;">mdi-alert</v-icon>
                            </v-card-title>

                            <v-card-text style="padding:20px 24px;font-weight:bold;">
                            Please make sure action field was selected.
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                style="color:red !important;"
                                color="primary"
                                text
                                @click="dialogErrorPending = false"
                            >
                                OK
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>
                    
                </v-tab-item>

                <!-- Content Tab Validation History -->
                <v-tab-item>

                    <!-- Button Export Table -->
                    <div class="section-btn-export">
                        <v-btn
                        color="green"
                        elevation="2"
                        @click="exportReport('excel','history')"
                        class="btn-export-table btn-excel"
                        small
                        >
                            <v-icon
                            color="white"
                            center
                            dark
                            small
                            >
                                mdi-microsoft-excel
                            </v-icon>
                        </v-btn>
                    </div>

                    <!-- Table History-->
                    <v-data-table
                    :headers="thead_validation_history"
                    :items="tbody_validation_history"
                    :header-props="{ sortIcon: null }"
                    class="elevation-1 tableValidationHistory headerDtSarawak"
                    :items-per-page="5"
                    no-select-on-click
                    >

                        <template v-slot:[`item.datetime`]="{ item }">
                            <span v-html="item.datetime"></span>
                        </template>

                        <template v-slot:[`item.requestedDt`]="{ item }">
                            <span v-html="item.requestedDt"></span>
                        </template>

                        <template v-slot:[`item.approvedDt`]="{ item }">
                            <span v-html="item.approvedDt"></span>
                        </template>

                    </v-data-table>
                    
                </v-tab-item>

            </v-tabs-items>

            

        </v-container>

        

        <template >
          <v-footer padless style="margin: 20px 0px;">

            <v-col
            class="text-center footer-report"
            cols="12"
            >
                <span class="one-text-footer mx-5">
                    <span class="red--text">C</span>
                    <span class="blue--text">=CALIBRATION</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span class="red--text">M</span>
                    <span class="blue--text">=MAINTENANCE</span>
                </span>
                <span class="one-text-footer mx-5 dontBreak">
                    <span class="red--text">DOR</span>
                    <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
                </span><br>
                <span class="one-text-footer mx-5">
                    <span class="red--text">OUTL</span>
                    <span class="blue--text">=OUTLIER</span>
                </span>
                <span class="one-text-footer mx-5 dontBreak">
                    <span class="red--text">PRBC</span>
                    <span class="blue--text">=PARAMETER RELATIONSHIP(BOD≥COD)</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span>NA</span>
                    <span class="blue--text">=DATA NOT AVAILABLE</span>
                </span>
            </v-col>
            <!-- <v-col
              class="text-center footer-report"
              cols="12"
            >
            
              <span class="one-text-footer mx-5">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer mx-5">
                <span>NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span>

            </v-col> -->
          </v-footer>
        </template>

    </div>


    

</template>

<script>
import axios from 'axios';
import Papa from "papaparse";

export default {
    data: () => ({

        GlobalRawData: [],
        
        
        pathModul: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
                // href: '/Mapvsadiewer',
            },
            {
                text: 'Data Validation',
                disabled: true,
                href: '/Operational/Validation',
            },
        ],
        itemStation: [],
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modalCalendar: false,
        menu2: false,
        modalCalendar2: false,

        // Variable/Object for value filtering
        valStation : null,
        valDate : null,
 
        // Title - Subtitle After Generate Data
        stationID: null,
        locationStation: null,

        tabs: null,
        tabsTypeValidation: [
            {tab: "QAQC Data Validation Report"},
            {tab: "QAQC Data Validation Pending"},
            {tab: "QAQC Data Validation History"}
        ],


        // Table
        loadingTable: false,
        loadingText: "",
        dateHeader: {
            text: 'Timestamp',
            sortable: false,
            value: 'datetime_a',
            divider: true,
            align: 'center',
        },
        stationIdHeader: {
            text: 'Station ID',
            sortable: false,
            value: 'station_a',
            divider: true,
            align: 'center',
        },

        thead_validation_report: [],
        tbody_validation_report: [],
        thead_fromTD_validation_report: [
            {text: 'Station ID', value: 'stationId', sortable: true, divider: true, align: 'center'},
            {text: 'Datetime', value: 'datetime', sortable: true, divider: true, align: 'center'},
            {text: 'Parameter', value: 'parameter', sortable: true, divider: true, align: 'center'},
            {text: 'Value', value: 'value', sortable: true, divider: true, align: 'center'},
        ],
        tbody_fromTD_validation_report: [],
        allDataFlag: [],
        textAreaValidationReport: "",
        dataAddVal: [],
        submitRemarksDataTDLoading: false,

        thead_validation_pending: [
            {text: 'Station ID', value: 'stationId', sortable: true, divider: true, align: 'center'},
            {text: 'Datetime', value: 'datetime', sortable: true, divider: true, align: 'center'},
            {text: 'Parameter', value: 'parameter', sortable: true, divider: true, align: 'center'},
            {text: 'Value', value: 'value', sortable: true, divider: true, align: 'center'},
            {text: 'Status', value: 'status', sortable: true, divider: true, align: 'center'},
            {text: 'Requester Remarks', value: 'requesterRemarks', sortable: true, divider: true, align: 'center'},
            {text: 'Requested By', value: 'requestedBy', sortable: true, divider: true, align: 'center'},
            {text: 'Requested Date', value: 'requestedDt', sortable: true, divider: true, align: 'center'},
            {text: 'Action', value: 'actionDropdown', sortable: false, divider: true, align: 'center'},
            {text: 'Action By Remarks', value: 'actionRemarks', sortable: false, divider: true, align: 'center'},
            {text: 'Submission', value: 'submitCheckbox', sortable: false, divider: true, align: 'center'},
        ],
        tbody_validation_pending: [],
        actionApprove: [],
        actionReject: [],
        actionForPending: [],
        remarksTextArea: [],
        selected: [],
        jsonForm: [],
        dialogSucceedValidateReport: false,
        dialogSucceedPending: false,
        dialogErrorPending: false,
        click_track: 1,
        whichUser: '',
        selectedPendingToHistory: [],

        thead_validation_history: [
            {text: 'Station ID', value: 'stationId', sortable: true, divider: true, align: 'center'},
            {text: 'Datetime', value: 'datetime', sortable: true, divider: true, align: 'center'},
            {text: 'Parameter', value: 'parameter', sortable: true, divider: true, align: 'center'},
            {text: 'Value', value: 'value', sortable: true, divider: true, align: 'center'},
            {text: 'Status', value: 'status', sortable: true, divider: true, align: 'center'},
            {text: 'Requester Remarks', value: 'requesterRemarks', sortable: true, divider: true, align: 'center'},
            {text: 'Requested By', value: 'requestedBy', sortable: true, divider: true, align: 'center'},
            {text: 'Requested Date', value: 'requestedDt', sortable: true, divider: true, align: 'center'},
            {text: 'Resolve By', value: 'approvedBy', sortable: false, divider: true, align: 'center'},
            {text: 'Resolve By Remarks', value: 'approverRemarks', sortable: false, divider: true, align: 'center'},
            {text: 'Resolve Datetime', value: 'approvedDt', sortable: false, divider: true, align: 'center'},
        ],
        tbody_validation_history: [],

        exportTableParamReport: [],
        exportTableParamPending: [],
        exportTableParamHistory: [],
        
        // stringText: ''

    }),
    methods: {

        // test(res){
        //     console.log(res);

        //     this.stringText = res;
        // },

        

        load(){
            this.getStation();
            this.whichUser = this.$store.getters.user.email;
        },

        getStation(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                this.itemStation = [];

                for (let i = 0; i < response.data.length; i++) {
                    this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);  
                }
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        // Get value from Selected Station
        selectValueStation: function(e) {
            // console.log(e);
            this.valStation = e;
        },


        getAllParam(){

            // console.log(this.stationID);

            axios.get('https://apis.spatialworks.com.my/sarawak/cms/params?stationid='+this.stationID+'&subindex=0', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                
                let keys = [
                    'text',
                    'value',
                    'divider',
                    'align',
                ],
                result = response.data.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

                this.thead_validation_report = result;
                this.thead_validation_report.unshift(this.stationIdHeader);
                this.thead_validation_report.unshift(this.dateHeader);

                this.getRawData(this.stationID,this.dateFrom,this.dateTo);
                
            })
            .catch(error => {
                console.log(error);
            })
        },


        getRawData(station,dateFrom,dateTo){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/rawdata?startdt='+dateFrom+'&enddt='+dateTo+'&stationid=all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                this.GlobalRawData = response.data

                let keyFlag = [];
                let valueFlagNotNull = [];
                for (let i = 0; i < response.data.length; i++) {
                    

                    // response.data[i]["datetime_a"] = response.data[i]["datetime_a"].replaceAll('T', '<br>');
                    
                    
                    
                    let keyData = Object.keys(response.data[0])
                    for (let x = 0; x < keyData.length; x++) {
                        
                        if (keyData[x].includes("FLAG")) {
                            // console.log(keyData[x]);
                            if (response.data[i][keyData[x]] == "NA" || response.data[i][keyData[x]] == null) {
                                response.data[i][keyData[x]] = null;
                            }
                            else{
                                // console.log("flag not null");
                                // console.log(response.data[i][keyData[x]]);
                                valueFlagNotNull.push(response.data[i][keyData[x]]);
                            }

                            keyFlag.push(keyData[x]);

                            

                        }
                        else{

                            // console.log(keyData[x]);

                            if (response.data[i][keyData[x]] == null) {
                                response.data[i][keyData[x]] = "NA";
                            }
                            
                            if(response.data[i][keyData[x]+"_FLAG"] != null || response.data[i][keyData[x]+"_flag"] != null
                            || response.data[i][keyData[x].split("_")[0]+keyData[x].split("_")[1]+"_FLAG"] != null
                            || response.data[i][keyData[x].split("_")[0]+keyData[x].split("_")[1]+"_flag"] != null
                            ){
                                response.data[i][keyData[x]] = null;
                                // console.log(response.data[i][keyData[x]]);
                            }

                        }
                    }

                    
                    

                    if (response.data[i]["station_a"] == station) {
                        this.tbody_validation_report.push(response.data[i]);
                    } 
                    
                    response.data[i]["datetime_a"] = this.convertDateFormat(response.data[i]["datetime_a"].split("T")[0]) 
                    + "<br>"
                    + this.convert12Hours(response.data[i]["datetime_a"].split("T")[1]);

                    
                    this.loadingTable = false;
                    this.loadingText = "";
                    
                }



                let keys = keyFlag,
                result = response.data.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

                this.allDataFlag.push(result);

                // console.log(this.allDataFlag);

                

                
                
            })
            .catch(error => {
                console.log(error);
            })

        },

        getDataValidation(station,dateFrom,dateTo){

            // console.log(station);
            // console.log(dateFrom);
            // console.log(dateTo);

            axios.get('https://apis.spatialworks.com.my/sarawak/maint/dataval?stationid='+station+'&startdt='+dateFrom+'T00:00:00&&enddt='+dateTo+'T23:59:59', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                // if(response.data.length == 0){
                //     console.log("Tiada Data");
                // }
                if(response.data.length > 0){

                    response.data.forEach(function(res){
                        // Add Attributes For Validation Pending
                        res["requestedDt"] = res["requestedDt"].replaceAll('T', '<br>');
                        res["datetime"] = res["datetime"].replaceAll('T', '<br>');
                        res["actionDropdown"] = ["Approve","Reject"];
                        res["actionRemarks"] = "";
                        // res["actionRemarks"] = res["requesterRemarks"];
                        res["submitCheckbox"] = false;

                        if (res["approvedDt"] != null) {
                            res["approvedDt"] = res["approvedDt"].replaceAll('T', '<br>'); 
                        }

                        
                    });

                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].status === "pending") {
                            this.tbody_validation_pending.push(response.data[i]);
                        }
                        else {
                            this.tbody_validation_history.push(response.data[i]);
                        }

                    }
                    

                }

                // console.log(this.$store.getters.user.email);

                
                this.getAllParam();

                

                

                
            })
            .catch(error => {
                console.log(error);
            })

            
        },

        

        generateTable(){

            this.loadingTable = true;
            this.loadingText = "Data Loading... Please wait";

            // Keluarkan Details SubText
            this.stationID = this.valStation.split(' - ')[0];
            this.locationStation = this.valStation.split(' - ')[1];
            

            // Unhide checkbox on header
            document.querySelector(".subTitle").style.display = "block";
            // let chk = document.querySelectorAll(".checkbox-header-dailyReport")
            // for (let i = 0; i < chk.length; i++) {
            // chk[i].style.display = "contents";
            // }

            // Clear First all Array Thead and Tbody
            this.thead_validation_report = [];
            this.tbody_validation_report = [];

            this.tbody_validation_pending = [];

            this.tbody_validation_history = [];

            this.exportTableParamReport = [];
            this.exportTableParamPending = [];

            
            this.getDataValidation(this.stationID,this.dateFrom,this.dateTo);

        },

        collectDataTD(station,dt,param,val,clickOnElement,dataFlag){


            // console.log(this.tbody_fromTD_validation_report);

            // UNCOMMENT code below if want only one td can be selected at a time ---------------------
            // document.querySelectorAll(".btn-click-td > span").forEach(function(res){
            //     res.style.backgroundColor = "white";
            //     res.style.color = "black";
            // })


            // console.log(val,dataFlag);

            // console.log(clickOnElement);
            // console.log(clickOnElement.srcElement);

            if (clickOnElement.path[0].classList[0] == "val-flag") {
                // clickOnElement.path[1].style.backgroundColor = "#0199be";
                // clickOnElement.path[1].style.color = "white";               
            }
            else{
                if (dataFlag != null || val == "NA") {
                    console.log("nothing happen");
                }
                else{
                    

                    this.tbody_fromTD_validation_report.push({
                        stationId: station,
                        datetime: dt,
                        parameter: param,
                        value: val
                    });

                    clickOnElement.target.style.backgroundColor = "#0199be";
                    // clickOnElement.target.style.backgroundImage = "linear-gradient(to top, #0081BA, #02B3C3)";
                    clickOnElement.target.style.color = "white";

                    document.getElementById("container_btnDpn_valReport").style.display = "block";
                }
                
            }

            console.log(this.tbody_fromTD_validation_report);



            // Display Button Submit & Button Cancel
            
   
        },

        submitDataTD(){
            document.getElementById("pageDepanValidationReport").style.display = "none";
            document.getElementById("pageLepasSubmitSelectedTD").style.display = "block";
        },

        cancelSelectTD(){

            this.tbody_fromTD_validation_report = [];

            document.querySelectorAll(".btn-click-td > span").forEach(function(res){
                res.style.backgroundColor = "white";
                res.style.color = "black";
            })
            

            document.getElementById("container_btnDpn_valReport").style.display = "none";
        },

        
        inputRemarksDataTD(textArea){
            // console.log(textArea);
            this.textAreaValidationReport = textArea;
        },

        submitRemarksDataTD(){
            // console.log(this.textAreaValidationReport);
            

            this.dataAddVal = [];

            for (let i = 0; i < this.tbody_fromTD_validation_report.length; i++) {
                this.tbody_fromTD_validation_report[i]["RequesterRemarks"] = this.textAreaValidationReport.toString();
                this.tbody_fromTD_validation_report[i]["datetime"] = this.tbody_fromTD_validation_report[i]["datetime"].replaceAll('<br>', 'T');
                
                // if (this.tbody_fromTD_validation_report[0]["value"] == "NA") {
                //     this.tbody_fromTD_validation_report[0]["value"] = null;
                // }

                // console.log(this.tbody_fromTD_validation_report[0]);

                this.dataAddVal.push(this.tbody_fromTD_validation_report[i]);
            }

            // console.log(this.dataAddVal);

            // this.dataAddVal.forEach(function(res){
            //     // res["datetime"] = this.convert24Hours(res["datetime"].split("T")[1]);
            //     // res["datetime"] = res["datetime"].split("T")[1]
            //     console.log(this.convert24Hours(res["datetime"].split("T")[1]));
            // })

            for(let i in this.dataAddVal){
                this.dataAddVal[i]["datetime"] = this.convertToDbDt(this.dataAddVal[i]["datetime"].split("T")[0])
                + "T" + this.convert24Hours(this.dataAddVal[i]["datetime"].split("T")[1]);
            }
            

            let jsonData = JSON.stringify(this.dataAddVal);

            // console.log(jsonData);
            this.submitRemarksDataTDLoading = true;

            axios.post('https://apis.spatialworks.com.my/sarawak/maint/adddataval', 
            jsonData,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                this.submitRemarksDataTDLoading = false;
                // document.getElementById("generateReport").click();
                this.dialogSucceedValidateReport = true;
                this.backToMainValidationReport();
                this.cancelSelectTD();
            },
            // this.backToMainValidationReport(),
            // this.cancelSelectTD()
            )
            .catch(error => {
                console.log(error);
            })


        },


        backToMainValidationReport(){
            document.getElementById("pageDepanValidationReport").style.display = "block";
            document.getElementById("pageLepasSubmitSelectedTD").style.display = "none";
        },


        // Get value from Action Approval Pending
        actionPending: function(id,action) {
            // console.log(id,action);

            for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i].split(" ~ ")[0] == id) {
                    this.selected[i] = this.selected[i].split(" ~ ")[0] + " ~ " + this.selected[i].split(" ~ ")[1] + " ~ " + action + " ~ " + this.selected[i].split(" ~ ")[3] + " ~ " + this.selected[i].split(" ~ ")[4]  + " - " + this.selected[i].split(" ~ ")[5]  + " ~ " + this.selected[i].split(" ~ ")[6];      
                    // console.log(this.selected[i]);
                }
            }
        },

        // Get value from Text Area Pending
        getdataTextArea: function(id,textArea) {
            // console.log(id,textArea);

            for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i].split(" ~ ")[0] == id) {
                    this.selected[i] = this.selected[i].split(" ~ ")[0] + " ~ " + this.selected[i].split(" ~ ")[1] + " ~ " + this.selected[i].split(" ~ ")[2] + " ~ " + textArea + " ~ " + this.selected[i].split(" ~ ")[4]  + " ~ " + this.selected[i].split(" ~ ")[5]  + " ~ " + this.selected[i].split(" ~ ")[6];
                    // console.log(this.selected[i]);
                }
            }
        },

        // Get all data row table from checkbox checked
        check: function(text,id,check,path) {

            // Index split Selected Array - Rujukan
            // 0 = id
            // 1 = boolean checked
            // 2 = action for pending
            // 3 = text area
            // 4 = Station ID
            // 5 = Datetime
            // 6 = Parameter

            if (check === true) {
                this.selected.push(id + " ~ " + check + " ~ " + path.path[3].cells[8].innerText + " ~ " + text + " ~ " + path.path[3].cells[0].innerText + " ~ " + path.path[3].cells[1].innerText + " ~ " + path.path[3].cells[2].innerText);
            }
            else if (check === false){
                for (let i = 0; i < this.selected.length; i++) {
                    if (this.selected[i].split(' ~ ')[0] == id.toString()) {
                        // console.log(this.selected[i]);
                        // this.selected[0] = this.selected[1];
                        this.selected.splice(i,1);
                    }
                }
            }

            this.selectedPendingToHistory = this.selected;

            // console.log(this.selected);

        },

        submitDataPending(){

            // console.log(this.selected);
            this.arrForm = [];

            // console.log(this.tbody_validation_pending);

            for (let i = 0; i < this.tbody_validation_pending.length; i++) {
                for (let x = 0; x < this.selected.length; x++) {
                    if (this.tbody_validation_pending[i].id == this.selected[x].split(" ~ ")[0]) {
                        // console.log(this.tbody_validation_pending[i]);
                        this.arrForm.push(this.tbody_validation_pending[i]);
                    }
                }
                
            }

            // console.log(this.jsonForm);

            let keys = [
                'id',
                'stationId',
                'datetime',
                'parameter',
                'approverRemarks',
            ],
            result = this.arrForm.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            // Index split Selected Array - For My Rujukan
            // 0 = id
            // 1 = boolean checked
            // 2 = action for pending
            // 3 = text area
            // 4 = Station ID
            // 5 = Datetime
            // 6 = Parameter

            // console.log(result);

            // this.selectedPendingToHistory = result;

            for (let i = 0; i < result.length; i++) {
                for (let x = 0; x < this.selected.length; x++) {
                    if (result[i].id == this.selected[x].split(" ~ ")[0]) {
                        result[i]["action"] = this.selected[x].split(" ~ ")[2];
                        result[i]["approverRemarks"] = this.selected[x].split(" ~ ")[3];
                        result[i]["datetime"] = result[i]["datetime"].replaceAll('<br>', ' ');
                    }
                }
            }

            // console.log(result);

            let keys2 = [
                'stationId',
                'datetime',
                'parameter',
                'action',
                'approverRemarks',
            ],
            arrayData = result.map(o => Object.assign(...keys2.map(k => ({ [k]: o[k] }))));
            
            
            // console.log(arrayData);

            let submitProceed = "off";
            for (let i = 0; i < arrayData.length; i++) {
                if (arrayData[i].action === "" || arrayData[i].action === null || arrayData[i].approverRemarks === null) {
                    submitProceed = "off";
                }
                else{
                    // submitProceed = null;
                    if (this.selected.length == 0) {
                        submitProceed = "off";
                    }
                    else{
                        submitProceed = null;
                    }
                }
            }

            


            console.log(submitProceed);

            // console.log(submitProceed);

            if (submitProceed === "off") {
                // console.log("masyuk");
                // console.log("submitProceed:" +  submitProceed);
                this.dialogErrorPending = true;
            }
            else{

                // console.log("submitProceed: is YES null!");
                // console.log("submit pending process");

                // Convert data into json
                let jsonData = JSON.stringify(arrayData);
                // console.log(jsonData);

                // document.getElementById('showSucceedValidatePending').click();
                
                axios.put('https://apis.spatialworks.com.my/sarawak/maint/editdataval',
                jsonData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$store.getters.user.token,
                    }
                })
                .then((response) => {
                    // console.log(response.data);
                    // console.log("Success!");
                    this.dialogSucceedPending= true;
                    // document.getElementById('showSucceedValidatePending').click();
                    
                })
                .catch(error => { //eslint-disable-line
                    // console.log(error);
                    // console.log("Unsucces~");
                })

            }
            

            
        },


        checkingAll(){
            // this.check()
            // this.tbody_validation_pending = []

            if (this.click_track == 1){
                this.check();
                this.tbody_validation_pending.forEach(function(res){
                    res["submitCheckbox"] = true;
                });
            }
            else if (this.click_track == 2) {
                this.check();
                this.tbody_validation_pending.forEach(function(res){
                    res["submitCheckbox"] = false;
                });
                this.click_track = 0;
            }

            this.click_track++;

            // console.log(this.tbody_validation_pending);

        },


        alreadySuccessSubmitPending(){
            this.dialogSucceedPending = false;
            document.getElementById('generateReport').click();
        },


        // Export Table into PDF/Excel
        exportReport(e,tab){

            // console.log(e);
            // console.log(tab);

            // console.log(export);

            let valueThReport = [];
            for (let i = 0; i < this.thead_validation_report.length; i++) {
                valueThReport.push(this.thead_validation_report[i].value);
            }

            // Manipulation Tbody Validation Report
            let keyData = Object.keys(this.tbody_validation_report[0])
            let keyParamsFlag = [];
            let keyParamsNonFlag = [];
            // console.log(keyData);
            for(let x in keyData){
                if (keyData[x].includes("FLAG")) {
                    // console.log(keyData[x]);
                    keyParamsFlag.push(keyData[x]);
                }
                else{
                    keyParamsNonFlag.push(keyData[x]);
                }
            }

            let keysFlag = keyParamsFlag,
            resultFlag = this.tbody_validation_report.map(o => Object.assign(...keysFlag.map(k => ({ [k]: o[k] }))));

            let keysReport = valueThReport,
            resultReport = this.tbody_validation_report.map(o => Object.assign(...keysReport.map(k => ({ [k]: o[k] }))));

            for(let i in resultFlag){
                for(let j in resultReport){
                    if (i == j) {
                        for(let x in keyParamsFlag){
                            for(let y in keyParamsNonFlag){
                                if (resultFlag[i][keyParamsNonFlag[x]+"_FLAG"] != null) {
                                    resultReport[j][keyParamsNonFlag[x]] = resultFlag[i][keyParamsNonFlag[x]+"_FLAG"];
                                }
                                else if (resultFlag[i][keyParamsNonFlag[x].split("_")[0]+keyParamsNonFlag[x].split("_")[1]+"_FLAG"] != null) {
                                    resultReport[j][keyParamsNonFlag[x]] = resultFlag[i][keyParamsNonFlag[x].split("_")[0]+keyParamsNonFlag[x].split("_")[1]+"_FLAG"];
                                }
                            }
                        }
                    }
                }
            }

            // console.log(resultReport);
            resultReport.forEach(function(res){
                res["datetime_a"] = res["datetime_a"].replaceAll("<br>",", ");
            });


            // let valuePending = [];
            // for (let i = 0; i < this.thead_validation_report.length; i++) {
            //     valuePending.push(this.thead_validation_pending[i].value);
            // }

            // let keysPending = valuePending,
            // resultPending = this.tbody_validation_pending.map(o => Object.assign(...keysPending.map(k => ({ [k]: o[k] }))));
            
            let valueHistory = [];
            for (let i = 0; i < this.thead_validation_history.length; i++) {
                valueHistory.push(this.thead_validation_history[i].value);
            }

            let keysHistory = valueHistory,
            resultHistory = this.tbody_validation_history.map(o => Object.assign(...keysHistory.map(k => ({ [k]: o[k] }))));

            // console.log(resultHistory);
            resultHistory.forEach(function(res){
                res["approvedDt"] = res["approvedDt"].replaceAll("<br>","T");
                res["datetime"] = res["datetime"].replaceAll("<br>","T");
            });
            
            if (e == "pdf") {
               console.log("exporting datatable to pdf");

            }
            else if (e == "excel") {
                console.log("exporting datatable to excel");

                let blob, url;
                let link = document.createElement("a");

                if (tab == "report") {
                    blob = new Blob([Papa.unparse(this.specialExcel(this.thead_validation_report,resultReport))], { type: 'text/csv;charset=utf-8;' });
                    url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", 'Data For Station '+ this.locationStation +' ('+ this.stationID +').csv');
                    
                }
                // else if (tab == "pending"){
                //     blob = new Blob([Papa.unparse(resultPending)], { type: 'text/csv;charset=utf-8;' });
                //     url = URL.createObjectURL(blob);
                //     link.setAttribute("href", url);
                //     link.setAttribute("download", 'QAQC Data Validation Pending.csv');
                // }
                else if (tab == "history"){
                    let zero = []; //Sementara
                    blob = new Blob([Papa.unparse(resultHistory)], { type: 'text/csv;charset=utf-8;' });
                    url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", 'QAQC Data Validation History.csv');
                }
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        },


    },
    mounted(){
        this.load();
    }
}
</script>

<style lang="scss">

    @import '~scss/main';

    /* Section Path File/Modul */
    .pathModul{
        padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a,
    .pathModul > li:nth-child(3) > div{
        color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
        padding: 0px;
    }

    /* Section Generate Result Datatable Vue */
    // .container-result-generated{
    //     height: calc(100vh - 265px);
    // }

    .subTitle{
        text-align: center !important;
        background: white;
        // color: #1976D2 !important;
        display: none;
        padding: 15px;
    }

    .tableValidationReport,
    .tableValidationPending,
    .tableValidationHistory{
        margin: 20px 0px;
    }

    .form-datatable{
        padding: 10px 0px 0px 0px;
    }

    // .tableValidationReport > div:nth-child(1) > table > thead > tr > th,
    // .tableValidationPending > div:nth-child(1) > table > thead > tr > th,
    // .tableValidationHistory > div:nth-child(1) > table > thead > tr > th{
    //     padding: 10px 16px;
    // }

    .tableValidationPending > div:nth-child(1) > table > thead > tr > th:nth-child(10){
        white-space: nowrap !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(1){
      white-space: nowrap !important;
    }

    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td:nth-child(2),
    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td:nth-child(8),
    .tableValidationHistory > div:nth-child(1) > table > tbody > tr > td:nth-child(2){
      white-space: nowrap !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td{
      padding: 0px !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(1),
    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(2){
      padding: 0px 16px !important;
    }

    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td{
      padding: 16px;
    }

    .action-chips > div:nth-child(1) > div:nth-child(1) > .v-select__slot > .v-select__selections > span{
        background: white !important;
    }

    .btn-after-table{
        margin: 0px 10px;
    }

    .tableValidationReport > .v-data-table__wrapper, 
    .tableValidationPending > .v-data-table__wrapper, 
    .tableValidationHistory > .v-data-table__wrapper {
        overflow-x: auto !important;
        overflow-y: auto !important;
        // max-height: 408px;
    }

    .v-data-table__empty-wrapper > td{
      text-align: left !important;
    }

    .section-btn-export{
        text-align: right;
    }

    .btn-export-table{
        margin-top: 12px;
    }

    .btn-click-td{
        background: white !important;
        height: inherit !important;
        width: -webkit-fill-available !important;
        padding: 0px !important;
    }

    /* .btn-click-td:hover{
        background: #0081BA !important;
        color: white;
    } */

    .btn-click-td > span{
        background: white;
        height: inherit !important;
        width: -webkit-fill-available !important;
        display: block;
        padding: 11px;
        font-size: 0.95em;
    }

    .btn-click-td > span:hover{
        background: #02b2c3 !important;
        color: white;
    }

    /* .btn-click-td.selectBtnClick{
        background: black !important;
    } */
    

    /* Special Section For Validation Report */

    /* contain 2nd table */
    #pageLepasSubmitSelectedTD, #container_btnDpn_valReport{
        display: none;
    }

    /* Footer Report */
    .footer-report{
      background: white;
    }
    
    // .one-text-footer{
    //   margin: 0px 10px;
    // }


</style>